<template>
    <v-dialog v-model="show" persistent max-width="500px">
        <v-card>
            <v-form @submit="addPlaceSoldCheck($v.currentPlaceSold)">
                <v-card-title>
                    <span class="headline">New Place Sold</span>
                    <v-spacer></v-spacer>
                    <v-btn icon type="submit" v-on:click.prevent="addPlaceSoldCheck($v.currentPlaceSold)">
                        <v-icon color="green">fas fa-check</v-icon>
                    </v-btn>
                    <v-btn icon @click.native="closeView()">
                        <v-icon color="red">fas fa-times</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field v-model="currentPlaceSold.retailerName"
                                              label="Retailer Name"
                                              :error-messages="retailerNameErrors"
                                              @input="$v.currentPlaceSold.retailerName.$touch()"
                                              @blur="$v.currentPlaceSold.retailerName.$touch()"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field v-model="currentPlaceSold.retailerWebsite"
                                              label="Retailer Website"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <!--<v-text-field v-model="currentPlaceSold.country"
                                              label="Country"></v-text-field>-->
                                <v-autocomplete :items="countries"
                                                v-model="currentPlaceSold.country"
                                                label="Country"></v-autocomplete>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <Locations v-on:locationList="getLocations($event)"></Locations>
                </v-card-text>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    import { globalFunctions } from '../../javascript/globalFunctions'

    import countryJson from '../../json/countries.json'

    export default {
        mixins: [validationMixin],
        components: {
            Locations: () => import('./placeSoldLocationList.vue')
        },
        data() {
            return {
                show: true,
                countries: [],
                currentPlaceSold: {
                    id: 0,
                    retailerName: '',
                    retailerWebsite: '',
                    country: '',
                    locations: [],
                    dateChanged: '',
                    objectState: 'New'
                }
            }
        },
        computed: {
            retailerNameErrors() {
                const errors = []
                if (!this.$v.currentPlaceSold.retailerName.$dirty) return errors
                !this.$v.currentPlaceSold.retailerName.required && errors.push('Retailer Name is required')
                return errors
            }
        },
        methods: {
            closeView: function () {
                this.$emit('itemShow', false)
            },
            addPlaceSoldCheck: function ($v) {
                if (this.placeSoldCheck($v)) {
                    this.addNewPlaceSold()
                    this.returnNewPlaceSold()
                    this.closeView()
                }
            },
            addNewPlaceSold: function () {
                let newEntry = globalFunctions.stringify(this.currentPlaceSold)

                newEntry.id = globalFunctions.getNextIdForObject(this.$store.state.PlaceSold)

                this.$store.commit('addNewPlaceSoldToStore', newEntry)
            },
            returnNewPlaceSold: function () {
                this.$emit('newPlaceSold', this.currentPlaceSold)
            },
            getLocations: function (locations) {
                this.currentPlaceSold.locations = locations
            },
            placeSoldCheck: function ($v) {
                $v.$touch(); //Triggers Error Messages

                if ($v.retailerName.$invalid || this.currentPlaceSold.locations.length === 0) {
                    return false
                }
                return true
            }
        },
        beforeMount() {
            this.countries = countryJson
        },
        validations: {
            currentPlaceSold: {
                retailerName: {
                    required
                }
            }
        }
    }
</script>